import "../css/index.scss"

var debug = true
const mobileBreakpoint = 782

let mobileView = false
let menuOpen = false
let menuChanging = false
let menuChangingTimeout

let adminBarHeight = 0
let siteHeaderHeight = 0
let lastScroll = 0
let prevScrollDelta = 0
let supportsPassive = false // for scroll event listeners

let orientationLandscape = true

jQuery(function ($) {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual"
  }
  $("html").addClass("js") // js is working

  // for ios touch -> enable active state for links
  document.addEventListener("touchstart", function () {}, false)

  // -----------------------------------------------
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")

  const siteTitleLink = $(".site-header__title > a")
  const menuMain = $(".menu-main")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const openCloseMenu = $(".nav-icon")
  const homeID = $("body").data("home-id")

  // -----------------------------------------------------------------------------------------------------

  setLandscape()
  touchSupport()

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  headerHeight()
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  siteContainer.hide()
  siteContainer.css("visibility", "visible")
  siteContainer.fadeIn()
  navContainer.fadeIn()

  setTimeout(() => {
    // safety
    headerHeight()
    // setMZBlockCovers()
  }, 100)

  const homeImage = $(".home-image__container")
  if (homeImage.length) {
    const dontshow = sessionStorage.getItem("dontShowHomeImage")
    // console.log("dontshow", dontshow)

    if (dontshow && dontshow == "true") {
      homeImage.hide()
      sessionStorage.setItem("dontShowHomeImage", "false")
    } else {
      const content = homeImage.siblings(".post-item-content")
      const header = $(".site-header__container--inner")
      header.css("border-bottom-color", "transparent")
      content.css("opacity", 0)
      menuMain.css("visibility", "hidden")
      menuMain.css("opacity", 0)
      openCloseMenu.css("visibility", "hidden")
      openCloseMenu.css("opacity", 0)

      homeImage.on("click", function () {
        $("html, body").animate(
          {
            scrollTop: 0
          },
          0
        )
        homeImage.fadeOut()
        content.css("opacity", 1)
        menuMain.css("visibility", "")
        menuMain.css("opacity", 1)
        openCloseMenu.css("visibility", "")
        openCloseMenu.css("opacity", 1)
        header.css("border-bottom-color", "")
      })
    }
  }

  const nachObenButtons = $(".mz-nach-oben-button")
  nachObenButtons.on("click", function () {
    $("html, body").animate(
      {
        scrollTop: 0
      },
      400
    )
  })

  function setMZBlockCovers() {
    const mzBlockCovers = $(".mz-block-cover")

    mzBlockCovers.each(function () {
      const top = $(this).offset().top
      const height = $(this).outerHeight()
      // const width = $(this).width()

      const imgBackground = $(this).children(".wp-block-cover__image-background")

      if (top + height >= window.innerHeight) {
        imgBackground.css("background-position", `50% 86%`)
      } else {
        if (mobileView) {
          const offset = 96 + (781 - window.innerWidth) * 0.27
          imgBackground.css("background-position", `50% ${$(window).scrollTop() + offset}px`)
        } else {
          getBackgroundSize(imgBackground)
        }
      }
    })
  }

  // function callbackf(data) {
  //   console.log("background size", data)
  // }

  function getBackgroundSize(element, callback) {
    const imgMaxHeight = 325

    if (mobileView) {
      element.css("background-position", `50% ${$(window).scrollTop()}px`)
    } else {
      var img = new Image(),
        // here we will place image's width and height
        width,
        height,
        // here we get the size of the background and split it to array
        backgroundSize = element.css("background-size").split(" ")
      // console.log("get background size", element, backgroundSize)

      // checking if width was set to pixel value
      if (/px/.test(backgroundSize[0])) width = parseInt(backgroundSize[0])
      // checking if width was set to percent value
      if (/%/.test(backgroundSize[0])) width = element.parent().width() * (parseInt(backgroundSize[0]) / 100)
      // checking if height was set to pixel value
      if (/px/.test(backgroundSize[1])) height = parseInt(backgroundSize[1])
      // checking if height was set to percent value
      if (/%/.test(backgroundSize[1])) height = element.parent().height() * (parseInt(backgroundSize[0]) / 100)

      img.onload = function () {
        // console.log("test image onload", this.height, this.width, this)
        // check if width was set earlier, if not then set it now
        if (typeof width == "undefined") {
          width = this.width
        }
        // do the same with height
        if (typeof height == "undefined") {
          height = (this.height / this.width) * width
        }
        // call the callback
        // callback({ width: width, height: height })
        // set background position
        // const top = element.offset().top + height - imgMaxHeight
        const top = element.offset().top + imgMaxHeight - height - 30
        element.css("background-position", `50% ${top}px`)
      }
      // extract image source from css using one, simple regex
      // src should be set AFTER onload handler
      img.src = element.css("background-image").replace(/url\(['"]*(.*?)['"]*\)/g, "$1")
    }
  }

  // open close mobile menu
  openCloseMenu.on("click", function () {
    if (!openCloseMenu.hasClass("checked")) {
      openMenu()
    } else {
      closeMenu()
    }
  })

  function openMenu() {
    menuChanging = true
    // console.log("open the menu", menuChanging)
    openCloseMenu.addClass("checked")
    menuMain.stop().slideDown(200, () => {
      clearTimeout(menuChangingTimeout)
      setTimeout(() => {
        menuOpen = true
        // console.log("slide down finsih")
        menuChanging = false
      }, 200)
    })
  }

  function closeMenu() {
    if (menuOpen) {
      // console.log("close the menu")
      openCloseMenu.removeClass("checked")
      menuMain.stop().slideUp(200, () => {
        menuChanging = false
        menuOpen = false
      })
    }
  }

  // menu and title navigation ------------------------------------------------------------------------------------------------
  siteTitleLink.on("click", function (e) {
    // e.preventDefault()

    let classlist = $("body").attr("class").split(/\s+/)
    let pageID = null
    $.each(classlist, function (index, item) {
      if (item.startsWith("page-id-")) {
        const splits = item.split("page-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })

    if (pageID && pageID != homeID) {
      // if we are not on the home page
      // set session storage flag
      sessionStorage.setItem("dontShowHomeImage", "true")
    } else {
      sessionStorage.setItem("dontShowHomeImage", "false")
    }
  })

  $(".dont-show-home-image").on("click", function (e) {
    // set session storage flag
    sessionStorage.setItem("dontShowHomeImage", "true")
  })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
      closeMenu()
    } else {
      // desktop
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      // closeMenu()
      menuMain.css("display", "")
      menuMain.css("padding", "")
      menuMain.css("margin", "")
      menuMain.css("height", "")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function headerHeight() {
    siteHeaderHeight = siteHeader.outerHeight()
    $(":root").css("--siteHeaderHeight", `${siteHeaderHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  function checkSupportsPassive() {
    // Test via a getter in the options object to see if the passive property is accessed
    try {
      var opts = Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true
        }
      })
      window.addEventListener("testPassive", null, opts)
      window.removeEventListener("testPassive", null, opts)
    } catch (e) {}
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      headerHeight()
      checkMobileView()
      headerHeight()
      // setMZBlockCovers()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  document.addEventListener(
    "scroll",
    function (e) {
      // const currentScroll = window.pageYOffset
      const currentScroll = window.scrollY
      const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))
      // console.log("scroll: ", currentScroll, lastScroll, scrollDelta, prevScrollDelta)

      prevScrollDelta = scrollDelta

      if (mobileView) {
        // if (window.scrollY === 0) {
        //   // on top
        // } else

        if (!menuChanging && currentScroll > lastScroll && scrollDelta > 5) {
          // DOWN ---
          // console.log("down down down", menuChanging)
          closeMenu()
        }

        // setMZBlockCovers()
      }

      lastScroll = Math.max(0, currentScroll)
    },
    supportsPassive ? { passive: true } : false
  )
})
